class Elementor_Single_Product {
    static instance;

    static getInstance() {
        if (!Elementor_Single_Product.instance) {
            Elementor_Single_Product.instance = new Elementor_Single_Product();
        }
        return Elementor_Single_Product.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-single-product.default', ( $scope ) => {
            if($scope.hasClass('elementor-change-text-on')) {
                let $product = $scope.find('.elementor-single-product');
                let $title = $product.data('title');
                let $button = $scope.find('.single_add_to_cart_button');
                let $price = $scope.find('.woocommerce-Price-amount');
                let $change_text = '<span class="woocommerce-price-text">' + $price.html() + '</span>' + $title;
                $button.html($change_text);
            }
        });
    }
}

Elementor_Single_Product.getInstance();