class Elementor_Image_Comparison{
    static instance;

    static getInstance() {
        if (!Elementor_Image_Comparison.instance) {
            Elementor_Image_Comparison.instance = new Elementor_Image_Comparison();
        }
        return Elementor_Image_Comparison.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-image-comparison.default', ( $scope ) => {
            let OpalCompElem = $scope.find(".osf-images-compare-container"),
                OpalCompSettings = OpalCompElem.data("settings");

            OpalCompElem.imagesLoaded(function() {
                OpalCompElem.twentytwenty({
                    orientation: OpalCompSettings["orientation"],
                    default_offset_pct: OpalCompSettings["visibleRatio"],
                    switch_before_label: OpalCompSettings["switchBefore"],
                    before_label: OpalCompSettings["beforeLabel"],
                    switch_after_label: OpalCompSettings["switchAfter"],
                    after_label: OpalCompSettings["afterLabel"],
                    move_slider_on_hover: OpalCompSettings["mouseMove"],
                    click_to_move: OpalCompSettings["clickMove"],
                    show_drag: OpalCompSettings["showDrag"],
                    show_sep: OpalCompSettings["showSep"],
                    no_overlay: OpalCompSettings["overlay"],
                    horbeforePos: OpalCompSettings["beforePos"],
                    horafterPos: OpalCompSettings["afterPos"],
                    verbeforePos: OpalCompSettings["verbeforePos"],
                    verafterPos: OpalCompSettings["verafterPos"]
                });
            });

        } );
    }
}
Elementor_Image_Comparison.getInstance();