class Elementor_Rotate_Images{
    static instance;

    static getInstance() {
        if (!Elementor_Rotate_Images.instance) {
            Elementor_Rotate_Images.instance = new Elementor_Rotate_Images();
        }
        return Elementor_Rotate_Images.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-rotate-images.default', ( $scope ) => {
            // Code Here

        } );
    }
}
Elementor_Rotate_Images.getInstance();