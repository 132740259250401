class Elementor_Testimonial_2{
    static instance;

    static getInstance() {
        if (!Elementor_Testimonial_2.instance) {
            Elementor_Testimonial_2.instance = new Elementor_Testimonial_2();
        }
        return Elementor_Testimonial_2.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-testimonials-2.default', ( $scope ) => {
            let $carousel = $scope.find('.owl-carousel-1');
            let $carousel2 = $scope.find('.owl-carousel-2');
            if($carousel.length > 0){
                $carousel.owlCarousel({
                    dots: true,
                    items:1,
                    singleItem:true,
                    loop:true,
                });
            }
            if($carousel2.length > 0){
                $carousel2.owlCarousel({
                    items:1,
                    singleItem:true,
                    loop:true,
                    touchDrag: true,
                    mouseDrag: false,
                    animateOut: 'fadeOut'
                });
            }

            if($carousel.length > 0) {
                $carousel.on('change.owl.carousel', function (event) {
                    if (event.namespace && event.property.name === 'position') {
                        var target = event.relatedTarget.relative(event.property.value, true);
                        $carousel2.owlCarousel('to', target, 300, true);
                    }
                });
            }

            let $itemactive = $scope.find('.owl-item.active .item-box');
            let $itemhover = $scope.find('.elementor-testimonial-image-wrapper');
            let OpalCompElem = $scope.find(".elementor-testimonial-image");
            OpalCompElem.imagesLoaded(function() {
                OpalCompElem.twentytwenty({
                    switch_before_label: false,
                    switch_after_label: false,
                });
            });
            // $itemhover.delay(200).fadeOut(200);
            let $id = $itemactive.data('trigger');
            if ($id) {
                $($id).addClass('active');
            }

            $carousel.on('changed.owl.carousel', function (event) {
                $($itemhover).removeClass('active');

                let current = event.item.index;
                let $id = $(event.target).find(".owl-item").eq(current).find(".item-box").data('trigger');
                if($id) {
                    $($id).addClass('active');
                }
            });

            // let $nav = $scope.find('.elementor-testimonial-image');
            // $nav.on('click', function(){
            //     let id = $(this).data('index');
            //     $carousel.trigger('to.owl.carousel', [id])
            // });

        } );
    }
}


Elementor_Testimonial_2.getInstance();