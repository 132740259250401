class Elementor_Testimonial{
    static instance;

    static getInstance() {
        if (!Elementor_Testimonial.instance) {
            Elementor_Testimonial.instance = new Elementor_Testimonial();
        }
        return Elementor_Testimonial.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-testimonials.default', ( $scope ) => {
            let $carousel = $scope.find('.owl-carousel');
            if($carousel.length > 0){
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }

            let $itemactive = $scope.find('.owl-item.active .item-box');
            let $itemhover = $scope.find('.elementor-testimonial-dot-image');
            let $nav = $scope.find('.elementor-testimonial-dot-image');

            let $id = $itemactive.data('trigger');
            if ($id) {
                $($id).addClass('active');
            }

            $carousel.on('changed.owl.carousel', function (event) {
                $($itemhover).removeClass('active');

                let current = event.item.index;
                let $id = $(event.target).find(".owl-item").eq(current).find(".item-box").data('trigger');
                if($id) {
                    $($id).addClass('active');
                }
            });

            $nav.on('click', function(){
                let id = $(this).data('index');
                $carousel.trigger('to.owl.carousel', [id])


            })

        } );
    }
}


Elementor_Testimonial.getInstance();